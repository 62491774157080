import { template as template_cce19978f3b2445c88e95f913723341c } from "@ember/template-compiler";
const WelcomeHeader = template_cce19978f3b2445c88e95f913723341c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
