import { template as template_a263768a04794613be7a031f2cc92c35 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a263768a04794613be7a031f2cc92c35(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
