import { template as template_ec32b142a99e46ceb1c57a22810f6354 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ec32b142a99e46ceb1c57a22810f6354(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
